"use client";

import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import Link from "next/link";
import { toast } from "sonner";
import { z } from "zod";

import { FormInput } from "@/components/custom";
import { Button } from "@/components/ui/button";
import { Form, FormDescription, FormField } from "@/components/ui/form";
import { useAuth } from "@/context";

import { asyncLogin } from "./actions";

const formSchema = z
  .object({
    email: z.string().email({ message: "Email хаяг буруу байна" }),
    pin: z.string().length(4, "Пин код 4 оронтой байх ёстой"),
  })
  .required();

export default function LoginForm() {
  const { login } = useAuth();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const { data, error } = await asyncLogin(values);
    if (!!data?.token) login();
    else toast.error(error, { position: "top-right" });
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-5">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormInput placeholder="И-мэйл" field={field} />
          )}
        />
        <FormField
          control={form.control}
          name="pin"
          rules={{ maxLength: 6, required: true }}
          render={({ field }) => (
            <FormInput
              nodeBeforeError={
                <FormDescription className="text-primary text-sm">
                  <Link href="/auth/forgot">Пин код мартсан?</Link>
                </FormDescription>
              }
              placeholder="Пин код"
              type="password"
              field={field}
            />
          )}
        />
        <Button type="submit" className="w-full !mt-20 rounded-2xl">
          Нэвтрэх
        </Button>
      </form>
    </Form>
  );
}
